/*==== define general style ====*/
/*-------- define colors in variables------------*/
/*-------- define colors for classes --------*/
.accent-background-color {
  background-color: #0b72b5; }

.accent-color {
  color: #0b72b5; }

.font-accent-color {
  color: #ffffff; }

/*-------- set background gradient--------*/
body {
  background: -moz-radial-gradient(center, ellipse cover, #7db9e8 0%, rgba(125, 185, 232, 0.3) 35%, white 80%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, #7db9e8 0%, rgba(125, 185, 232, 0.3) 35%, white 80%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, #7db9e8 0%, rgba(125, 185, 232, 0.3) 35%, white 80%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7db9e8', endColorstr='#ffffff',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
  font-family: "Open Sans",sans-serif; }

/*-------- define font types --------*/
h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px; }

h2 {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px; }

/*-------- reset defaults styles --------*/
.prevent-pointer-events {
  pointer-events: none; }

.show {
  display: block; }

.btn {
  border-radius: 0;
  border: none; }

fieldset {
  min-width: 100%; }

/*====== basic styles for medi hp ======*/
.background-color-container {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 6px 17px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 6px 17px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 6px 17px -3px rgba(0, 0, 0, 0.75); }

a:focus {
  outline: none;
  outline-offset: -2px; }

/*---------- header image or carousel at the top of every page-------*/
header {
  position: relative; }

.container-image {
  padding: 0; }

.header-img {
  height: 620px;
  background-size: cover; }

/*---------- carousel for home screen ---- replaced the static header image
             Background images are set within the HTML using inline CSS, not here -------*/
.item,
.active,
.carousel-inner {
  height: 100%; }

.fill {
  width: 100%;
  height: 100%;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover; }

.carousel {
  height: 620px;
  position: relative; }

/*===== top menu =====*/
.top-menu-wrapper {
  position: relative; }

.top-menu {
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-left: 0;
  /*-----different tabs----*/ }
  .top-menu > div {
    padding: 12px;
    margin-right: 3px; }
    .top-menu > div > a {
      color: #ffffff;
      /*&:hover {
                color: #9c9e9f;
                text-decoration: none;
                cursor: pointer;
            }*/ }
    .top-menu > div > span {
      color: #ffffff; }
  .top-menu .telephone,
  .top-menu .opening-tab {
    width: 145px; }
  .top-menu .mail-contact {
    width: 65px; }
  .top-menu .top-menu-item {
    height: 45px; }
    .top-menu .top-menu-item a:hover {
      color: #707172;
      text-decoration: none; }
    .top-menu .top-menu-item:hover {
      background-color: #d9dadb;
      color: #707172; }
  .top-menu .mail-contact {
    padding: 7px 15px; }
    .top-menu .mail-contact .mail-icon {
      font-size: 25px;
      transform: scale(1.5, 1); }

/*------ openinghours show displayed by hover-------*/
.openinghours {
  width: 300px;
  right: 0;
  position: absolute;
  top: 47px;
  display: none;
  padding: 10px; }

/*======== Sidebar is the main nav in this template ===========*/
/*-------- Customer Logo in Sidebar - align center -----------*/
.customer-logo {
  height: 215px;
  width: 100%; }
  .customer-logo .link-logo {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    text-align: center; }

/*-------- main nav styles -------------*/
.side-menu {
  margin-top: -215px;
  margin-right: 15px;
  padding: 0;
  width: 32%; }

.side-nav {
  border-top: 1px solid #707172; }
  .side-nav .panel {
    background-color: #fff;
    border: 0 solid transparent; }
  .side-nav .panel-title {
    font-size: 14px; }
  .side-nav .panel-default {
    border-color: transparent;
    border-top: 1px solid #707172; }
  .side-nav .panel-body {
    padding: 5px 25px; }
  .side-nav .panel-heading {
    background: none; }

.nested-nav-item li {
  margin: 15px 0; }
  .nested-nav-item li:first-child {
    margin: 0 0 15px; }
  .nested-nav-item li:last-child {
    margin: 15px 0 0; }

/*--------expand and collapse area in the menu used bootstraps panel
          overwrite bootstraps default panel style------------*/
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: transparent; }

.side-nav .panel-group {
  margin-bottom: 0; }
  .side-nav .panel-group .panel {
    border-radius: 0; }
  .side-nav .panel-group .panel + .panel {
    margin-top: 0; }

.panel-default > .panel-heading:first-child, .side-nav > .nav-item:first-child {
  border: 1px solid transparent; }

.panel-default > .panel-heading,
.side-nav > .nav-item {
  color: #707172;
  border-top: 1px solid #707172;
  background-color: #ffffff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0; }
  .panel-default > .panel-heading a,
  .side-nav > .nav-item a {
    padding: 15px;
    color: #707172;
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none; }
  .panel-default > .panel-heading:first-child,
  .side-nav > .nav-item:first-child {
    border-top: 1px solid transparent; }
  .panel-default > .panel-heading:hover,
  .side-nav > .nav-item:hover {
    background-color: #d9dadb;
    color: #000;
    cursor: pointer; }
  .panel-default > .panel-heading.active,
  .side-nav > .nav-item.active {
    background-color: #0b72b5;
    color: #ffffff; }
    .panel-default > .panel-heading.active a,
    .side-nav > .nav-item.active a {
      color: #ffffff;
      text-decoration: none; }

/*---- arrow icon in expanded nav item ----*/
.link-arrow-icon {
  margin-right: 10px; }

.active.link-arrow-icon {
  color: #0b72b5; }

/*contact form under Siderbar with Tab*/
.tab-menu {
  margin-top: 35px; }

.nav-item {
  list-style: none; }

.nav-tabs {
  border-bottom: 1px solid transparent; }

.nav-tabs > li {
  background-color: #9c9e9f;
  margin-right: 3px; }
  .nav-tabs > li > a {
    border-radius: 0;
    border: 0 solid transparent;
    color: #ffffff;
    margin-right: 0;
    padding: 10px 50px 10px 15px; }

.nav-tabs > li.active a,
.nav-tabs > li.active a:focus,
.nav-tabs > li.active a:hover,
.nav > li > a:hover {
  background-color: #0b72b5;
  color: #ffffff;
  border: 0 solid transparent; }

.tab-content {
  padding: 15px; }

.send-btn {
  margin-top: 15px;
  border-radius: 0; }

.contact-phone {
  font-size: 2rem;
  color: #0b72b5; }

.container-main-content {
  margin-top: -30px;
  padding: 0 30px 25px; }

.article-img {
  width: 200px;
  height: auto;
  float: left; }

/*===== Footer section =====*/
footer {
  margin-top: 50px;
  color: #ffffff;
  margin-bottom: 100px; }
  footer .row {
    padding: 20px 0 10px;
    margin-left: 0;
    margin-right: 0; }
  footer a {
    color: #fff;
    cursor: pointer; }
    footer a:hover {
      color: #f5f5f5; }

.footer-page-links {
  text-align: right; }

.jameda-siegel-wrapper {
  margin: 40px 0 0 -20px; }

/* ===== specific styles for home page ===== */
/*----- teaser at the bottom of home page----*/
.subheadline-link:hover {
  text-decoration: none; }

.subheadline-teaser:hover {
  background-color: #d9dadb;
  text-decoration: none;
  color: #707172; }

.subheadline-teaser {
  padding: 5px; }

.icon-news {
  color: #9c9e9f; }

/* ===== specific styles for profile page ===== */
.profile-career > li {
  margin: 15px 0; }

/* ===== specific styles for contact page ===== */
/*---- goolge maps at top of the page instead of header image----*/
.google-maps-wrapper iframe {
  width: 100%;
  height: 100%; }

/*--- style the formular -----*/
textarea {
  resize: vertical; }

.formular {
  padding: 0; }
  .formular .form-group.col-md-6 {
    padding: 0; }

/*-----  "routenplaner" btn ---*/
.map-btn {
  border-radius: 0;
  position: absolute;
  right: 10px;
  top: 20px; }

/* ===== specific styles for gallery page ===== */
.img-gallery > .col-md-4 {
  overflow: hidden;
  height: 180px; }
  .img-gallery > .col-md-4 a > img {
    min-height: 185px;
    width: auto; }

.gallery-image {
  margin: 10px 0; }

img.img-responsive {
  width: 100%; }

.img-gallery img {
  margin: 15px 0; }

.row .img-float-free {
  width: 50%;
  float: right; }

.margin-top {
  margin-top: 25px; }

/*======= posts for pages ======*/
.square-shape {
  overflow: hidden;
  height: 270px; }

.post-images {
  margin-top: 40px; }

.post-image img {
  max-width: 100%;
  margin-bottom: 20px; }

.post-list .post-content {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px; }

.post-content:last-child {
  border: none; }

/*======= Set default styles for mobile navbar from bootstrap =====*/
.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
  margin-right: 0px;
  margin-left: 0px;
  display: inherit; }

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .open > a {
  background: none; }

.dropdown-menu > li > a {
  white-space: normal; }

.navbar {
  min-height: 46px; }

.navbar-default {
  background-color: #fff;
  border-color: #d9dadb; }

.navbar-toggle {
  border-color: transparent !important;
  margin-bottom: 4px; }
  .navbar-toggle .icon-bar {
    background-color: #0b72b5 !important; }

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: #0b72b5; }

.navbar-brand {
  padding: 0px;
  float: right; }

.mobile-logo-link {
  display: block;
  margin: 20px auto;
  width: 80%; }

.mobile-logo {
  width: 100%; }

.navbar-fixed-top ul {
  margin-bottom: 0; }

.mobile-nav-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0; }

.jameda-wimpel {
  display: inline-block;
  position: absolute;
  right: 0;
  top: -8px;
  z-index: 1; }

/*===== Media Queries =====*/
/*----------- small screens / mobile ----------- */
@media screen and (max-width: 769px) {
  .container-main-content {
    margin-top: 0; }
  .container-image {
    margin-top: 40px; }
  .square-shape {
    margin-top: 15px; }
  .btn-lg {
    font-size: 16px;
    padding: 12px; }
  footer {
    margin-bottom: 0px; }
  .teaser {
    margin-bottom: 15px; }
  .container {
    padding-left: 0;
    padding-right: 0; }
  .side-collapse-container {
    width: 100%;
    position: relative;
    left: 0;
    transition: left .4s; }
  .side-collapse-container.out {
    left: 200px; }
  .side-collapse {
    top: 50px;
    bottom: 0;
    left: 0;
    width: 200px;
    position: fixed;
    overflow-y: scroll;
    transition: width .4s; }
  .side-collapse.in {
    width: 0; }
  footer ul li {
    font-size: 12px; }
  footer .footer-page-links {
    text-align: left; }
  .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
    display: inline-block;
    width: 100%; }
  .carousel {
    height: 320px; } }

@media screen and (min-width: 769px) {
  .navbar-right {
    float: right !important;
    margin-right: -15px;
    position: fixed;
    right: 70px; }
  .jameda-wimpel {
    top: 32px; } }

/*-----------  Large screens----------- */
@media only screen and (max-width: 992px) {
  /*body {display: none;}*/
  .navbar-nav > li {
    float: none;
    margin-left: 0px;
    border-bottom: 1px solid #eee; }
  .navbar-nav {
    float: none; }
  .nav.navbar-nav {
    clear: both;
    float: left;
    margin: 0;
    width: 100%; }
  .navbar-toggle {
    display: block; }
  .side-collapse {
    top: 47px;
    bottom: 0;
    left: 0;
    width: 300px;
    position: fixed;
    overflow-y: scroll;
    transition: width .4s; }
    .side-collapse.in {
      display: none; }
  .side-menu {
    margin-top: -190px; }
  .customer-logo {
    height: 190px; } }

@media screen and (min-width: 992px) {
  .container-main-content .row {
    margin-top: 20px; }
  .side-menu {
    width: 31.5%; }
  .jameda-wimpel {
    top: -15px; } }

/* ===== specific device styles ===== */
/*----------- all iPad devices in potrait ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .side-menu,
  .top-menu {
    display: none;
    position: relative; }
  .header-img {
    height: 330px; } }

/*-----------  all iPad devices in landscape ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .side-menu {
    width: 31%; } }
